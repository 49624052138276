<template>
  <div class="widgetContainer widgetContainer--scrollable bg-white shifl_instant_pay_search_widget">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <div class="title">
          Shifl Instant Pay
        </div>
        <i class="el-icon-close" @click="drawerClose" />
      </div>
    </div>
    <div class="widgetContainer__body">
      <el-form @submit.native.prevent ref="searchForm" :model="searchForm">
        <el-form-item
          prop="searchKey"
          :rules="[
            { required: true, message: 'Please input email address', trigger: 'blur' },
            { type: 'email', message: 'Please input correct email address' }
          ]"
          class="is-no-asterisk"
          label="Search Person by Email">
          <el-input @input.native="toInvite = ''" type="email" placeholder="hello@shifl.com" v-model="searchForm.searchKey">
            <el-button type="default" slot="append" @click="searchPersons">
              <i class="el-icon-search" />
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <template v-if="searching">
        <UserNameCardSkeleton :count="2" />
      </template>
      <template v-else>
        <el-card v-for="person in persons" :key="person.id" class="person_card mb-1" @click.native="selectPerson(person)">
          <div class="initialsInfoContact initialCaps">
            <span>{{ person.firstName.charAt(0) }}</span>
          </div>
          <div class="details">
            <p class="name initialCaps">
              {{ person.firstName }} {{ person.lastName }}
            </p>
            <p class="date initialCaps">
              {{ person.phone }}
            </p>
          </div>
          <span class="icon-Arrow-small-right icon" />
        </el-card>
        <el-card v-if="showInvite">
          <div style="display: flex; align-items: center; flex-direction: column">
            <span class="mb-1">No person found.</span>
            <span class="mb-1">Invite {{ toInvite }} to Shifl Capital.</span>
            <el-button type="primary" class="brand" @click="sendInvite">
              Send
            </el-button>
          </div>
        </el-card>
      </template>
    </div>
  </div>
</template>

<script>
import {programService} from '@/services/solidfi';
import {solidRequest} from '@/services';
import UserNameCardSkeleton from '@/components/Skeleton/UserNameCardSkeleton';
export default {
  name: 'Search',
  components: {UserNameCardSkeleton},
  data() {
    return {
      searchForm:{
        searchKey: '',
      },
      toInvite: '',
      searching: false,
      persons: []
    }
  },
  computed:{
    showInvite(){
      return !!this.toInvite
    }
  },
  methods: {
    selectPerson(person){
      if (person.kyc.status != 'approved') return this.$message({message: `KYC STATUS: ${person.kyc.status.toUpperCase()}. PLEASE PICK ANOTHER`, type: 'error', duration: 5000});
      this.$store.commit('send/set_shifl_instant_pay_person',person);
      this.$emit('goTo', 'PersonAccounts');
    },
    async sendInvite(){
      const loader = this.showLoader();
      try {
        let response =  await solidRequest.post(solidRequest.backendSolidBaseUrl + '/send-invite',{email: this.searchForm.searchKey,url: window.location.origin});
        this.$message({
          message: response.data.message,
          type: 'success'
        });
        this.drawerClose()
      }catch (e) {
        this.apiErrorMessage(e)
      }
      loader.close()
    },
    async searchPersons() {
      if (await this.$refs.searchForm.validate())
      {
        this.searching = true
        const result = await programService.persons({email: this.searchForm.searchKey})
        this.persons = result.data.data.sort((a,b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase()) ? 1 : ((b.firstName.toLowerCase() > a.firstName.toLowerCase()) ? -1 : 0))
        if (result.data.total === 0) this.toInvite = this.searchForm.searchKey;
        else this.toInvite = ''
        this.searching = false
      }
    }
  }
}
</script>

<style lang="scss">
.shifl_instant_pay_search_widget {
  .person_card {
    .el-card__body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid transparent;
      transition: 0.2s;
      cursor: pointer;
      border-bottom: 1px solid #eeeeee;

      .initialsInfoContact {
        background: #f6f7f9;
        color: var(--branding-primary);
      }

      .details {
        margin: 0 auto 0 15px;
        padding-right: 8px;

        .name {
          font-size: 15px;
          line-height: 20px;
        }

        .date {
          color: rgba(#3c3c43, 0.6);
          font-size: 12px;
          padding-top: 2px;
        }
      }

      .icon {
        color: #c7c7c9;
        font-size: 13px;
      }
    }
  }
}
</style>